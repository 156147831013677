<template>
  <v-row>
    <v-col>
      <h1 class="text-h5">
        Transaction History
        <v-btn x-small
               small
               icon
               depressed
               @click="() => loadInitialPage(this.store)">
          <v-icon>mdi-reload</v-icon>
        </v-btn>
      </h1>
      <v-data-table
          class="fill-height"
          dense
          :loading="isLoading"
          :items-per-page="20"
          :footer-props="footerProps"
          :headers="headers"
          :server-items-length="serverCount"
          v-on:update:page="updatePage"
          :page="currentPageNumber"
          :items="currentPage">
          <template v-slot:item.timestamp="item">
            <span>{{new Date(Date.parse(item.value)).toLocaleString()}}</span>
          </template>
          <template v-slot:item.amount="item">
            {{item.value | currency}}
          </template>
          <template v-slot:item.status="item">
            <span v-bind:class="getStatusClass(item.value)" class="text--accent-4 font-weight-bold">{{item.value}}</span>
          </template>
          <template v-slot:item.paid="item">
            <span>{{item.value != null && item.value ? 'Card' : 'Cash'}}</span>
          </template>
          <template slot="no-data">
            <template v-if="store == null">
              <div>
                Please choose store to get transaction history.
              </div>
            </template>
            <template v-else>
                No current load requests.
            </template>
          </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import {mapState} from "vuex";
import CustomerService from "@/services/CustomerService";

const PAGE_SIZE = 20
export default {
  name: "TransactionHistory",
  data: () => ({
    headers: [
      {text: 'Request Date', value: 'timestamp'},
      {text: 'Card Id', value: 'cardId'},
      {text: 'Amount', value: 'amount'},
      {text: 'Status', value: 'status'},
      {text: 'Payment Type', value: 'paid'}
    ],
    statusClasses: {
      'Pending': 'amber--text',
      'Approved': 'teal--text',
      'Rejected': 'red--text'
    },
    currentPage: [],
    isLoading: false,
    currentPageNumber: 1,
    footerProps: {'items-per-page-options': [PAGE_SIZE], disablePagination: false},
  }),
  created() {
    if (this.store != null) {
      this.loadInitialPage(this.store)
    }
  },
  mounted() {
    this.$root.$on('request-success', () => {
      this.loadInitialPage(this.store)
    });
  },
  computed: {
    ...mapState({
        user: state => state.vendor.user,
        store: state => state.vendor.store,
        loadRequests: state => state.cards.loadRequests,
        serverCount: state => state.cards.serverCount,
        nextPage: state => state.cards.nextPage
    })
  },
  methods: {
    getStatusClass(value) {
      return this.statusClasses[value]
    },
    loadInitialPage(newStore) {
      console.log('load Initial Page')
      this.currentPageNumber = 1
      if (newStore == null) {
        this.currentPage = []
        return;
      }
      this.isLoading = true
      this.user.getIdToken()
          .then(token => CustomerService.searchStoreRequests(token, newStore.websafeKey, PAGE_SIZE, null))
          .then(data => {
            this.$store.dispatch('cards/onInitialPage', data)
            this.currentPage = data.requests || []
          })
          .finally(() => this.isLoading = false)
    },
    updatePage(pageNumber) {
      console.log('Update page number')
      this.currentPageNumber = pageNumber
      const pageIndex = pageNumber - 1
      const nextIndex = pageIndex * PAGE_SIZE

      // Don't do anything when triggered by initial load
      if (this.loadRequests.length === 0) {
        return;
      }

      // [NOTE] Since we can only jump one page at a time,
      //        the next index is greater than we loaded
      //        we need to load from the server.
      if (this.store != null && nextIndex >= this.loadRequests.length) {
        this.isLoading = true
        this.user.getIdToken()
            .then(token => CustomerService.searchStoreRequests(token, this.store.websafeKey, PAGE_SIZE, this.nextPage))
            .then(data => {
              this.$store.dispatch('cards/onNewPage', data)
              this.currentPage = data.requests || []
            })
            .finally(() => this.isLoading = false)
      } else {
        this.currentPage = this.loadRequests.slice((this.currentPageNumber - 1) * PAGE_SIZE, this.currentPageNumber * PAGE_SIZE)
      }
    }
  },
  watch: {
    store(newStore) {
      this.loadInitialPage(newStore)
    },
    isLoading(newValue) {
      // Disable while loading
      this.footerProps.disablePagination = newValue
    }
  }
}
</script>

<style scoped>

</style>
