<template>
  <v-container class="fill-height">
    <v-row class="justify-center fill-height">
      <v-col cols="10" sm="8" md="8" lg="6">
        <v-card class="mt-3 fill-height" elevation="5" :loading="loggingIn">
          <v-row class="justify-center">
            <v-col sm="8">
              <v-img :src="require('../../assets/wlt-logo.jpg')"
                     class="mx-auto"
                     contain
                     max-height="150"
              ></v-img>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-form class="mt-5">
            <v-row class="justify-center">
              <v-col cols="10" sm="8">
                <v-text-field
                    v-model="email"
                    :rules="[rules.emailValid, rules.emailRequired]"
                    :disabled="loggingIn"
                    label="E-mail"
                    outlined
                    required>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col cols="10" sm="8">
                <v-text-field
                    v-model="password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="passwordRules"
                    :type="showPassword ? 'text' : 'password'"
                    name="input-10-1"
                    label="Password"
                    hint="At least 8 characters"
                    counter
                    outlined
                    :disabled="loggingIn"
                    @click:append="showPassword = !showPassword"
                    @keyup.enter="login"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense class="justify-center">
              <v-col class="text-center">
              <v-btn small plain @click="forgotPassword">Forgot Password</v-btn>
              </v-col>
            </v-row>
            <v-row dense justify="center">
              <v-col cols="10" sm="8" class="text-right">
                <v-btn right dark large outlined color="primary"
                       @click="login"
                       :loading="loggingIn">Sign In</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase/app"
import AdminService from "@/services/CustomerService";
export default {
  name: "Login",
  data: () => ({
    email: '',
    password: '',
    showPassword: false,
    invalidPassword: false,
    rules: {
      emailRequired: v => !!v || 'E-mail is required',
      emailValid: v => /.+@.+/.test(v) || 'E-mail must be valid',
      required: value => !!value || 'Required.',
      min: v => v.length >= 8 || 'Min 8 characters',
      invalidPassword: () => (`The email and password you entered don't match`)
    },
    loggingIn: false
  }),
  computed: {
    passwordRules: function () {
      return [this.rules.required, this.rules.min].concat(this.invalidPassword ? [this.rules.invalidPassword] : [])
    }
  },
  methods: {
    login: function() {
      if (this.email === '' || this.password === '') {
        return;
      }
      this.loggingIn = true;
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
          .then(() => firebase.auth().signInWithEmailAndPassword(this.email, this.password))
          .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            this.$store.commit('vendor/onLogin', user)
            return Promise.resolve(user);
          })
          .then((user) => user.getIdToken())
          .then((token) => AdminService.getAccountProfile(token))
          .then((accountAndPref) => {
            const account = accountAndPref.account
            if (account.requiresReset) {
              this.$router.push({name: 'ResetPassword'})
              return;
            }
            this.$router.push({name: 'Dashboard'})
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            if (errorCode === 'auth/wrong-password') {
              this.invalidPassword = true
              return
            }
            console.log('Error', errorCode, errorMessage)
          })
          .finally(() => this.loggingIn = false)
    },
    forgotPassword() {
      console.log('Triggered?')
      this.$router.push({name: 'ForgotPassword'})
    }
  }
}
</script>

<style scoped>

</style>
