<template>
  <v-container fluid class="fill-height align-content-start">
    <v-row class="flex-grow-0 fill-height">
      <v-col>
        <CardLoader></CardLoader>
      </v-col>
      <v-col class="fill-height">
        <TransactionHistory></TransactionHistory>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CardLoader from "@/components/dashboard/CardLoader";
import TransactionHistory from "@/components/dashboard/TransactionHistory";

export default {
  name: "Dashboard",
  components: {CardLoader, TransactionHistory},
  methods: {
    onLoadSuccess(request) {
      console.log('On load success', request)
    }
  }
}
</script>

<style scoped>
</style>
