<template>
  <v-container class="fill-height">
    <v-row class="justify-center fill-height">
      <v-col cols="10" sm="8" lg="6">
        <v-card class="mt-3 py-3 fill-height" elevation="5">
          <v-row class="mt-5 justify-center text-center">
            <v-col cols="10" sm="8">
              <h1 class="mx-auto text-h3">Welcome</h1>
              <p class="mx-auto mt-3"> 👋 <span class="text--secondary text-body-1">Set up your own password to log into the vendor console in the
                future</span></p>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-form class="mt-5">
            <v-row class="mt-5 justify-center">
              <v-col cols="10" sm="8">
                <v-text-field
                    v-model="password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="passwordRules"
                    :type="showPassword ? 'text' : 'password'"
                    name="input-10-1"
                    label="Password"
                    hint="At least 8 characters"
                    counter
                    outlined
                    :disabled="submitting"
                    @click:append="showPassword = !showPassword"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col cols="10" sm="8">
                <v-text-field
                    v-model="confirmPassword"
                    :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="confirmPasswordRules"
                    :type="showConfirmPassword ? 'text' : 'password'"
                    name="input-10-1"
                    label="Confirm password"
                    hint="At least 8 characters"
                    counter
                    outlined
                    :disabled="submitting"
                    @keyup.enter="submit"
                    @click:append="showConfirmPassword = !showConfirmPassword"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-3" justify="center">
              <v-col cols="10" sm="8" class="text-right">
                <v-btn right dark large outlined color="primary" @click="submit" :loading="submitting">Submit</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import firebase from "firebase/app";
import AdminService from "@/services/CustomerService";

export default {
  name: "ResetPassword",
  data: () => ({
    submitting: false,
    password: '',
    showPassword: false,
    showConfirmPassword: false,
    confirmPassword: '',
    invalidPassword: true,
    rules: {
      required: value => !!value || 'Required.',
      min: v => v.length >= 8 || 'Min 8 characters',
      invalidPassword: () => (`The email and password you entered don't match`),
      passwordsNotMatch: () => ('The passwords do not match.')
    }
  }),
  computed: {
    passwordsNotMatch: function () {
      return this.password !== this.confirmPassword
    },
    passwordRules: function () {
      return [this.rules.required, this.rules.min]
    },
    confirmPasswordRules: function () {
      const minRules = [this.rules.required, this.rules.min]
      if (this.passwordsNotMatch) {
        minRules.push(this.rules.passwordsNotMatch)
      }
      return minRules
    }
  },
  methods: {
    submit: function() {
      if (this.password === '' && this.confirmPassword !== this.password) {
        return;
      }
      this.submitting = true;
      const currentUser = firebase.auth().currentUser;
      if (currentUser == null) {
        return;
      }
      currentUser.getIdToken()
          .then(token => AdminService.resetPassword(token, this.password))
          .then(() => {
            this.$router.push({name: 'Dashboard'})
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            if (errorCode === 'auth/wrong-password') {
              return
            }
            console.log('Error', errorCode, errorMessage)
          })
          .finally(() => this.submitting = false)
    }
  }
}
</script>

<style scoped>

</style>
