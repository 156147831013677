import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#2B3B4A',
                secondary: '#cd7231',
                accent: colors.amber.accent2
            }
        }
    }
});

