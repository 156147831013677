<template>
  <v-app>
    <v-app-bar
      app
      dark
      color="primary">
      <div class="d-flex align-center">
<!--        <v-img-->
<!--          alt="Why Leave Town Logo"-->
<!--          class="shrink mr-2"-->
<!--          contain-->
<!--          :src="require('./assets/wlt-logo.jpg')"-->
<!--          transition="scale-transition"-->
<!--          width="40"-->
<!--        />-->
        <v-toolbar-title>
          <span v-if="isLoggedIn">
            {{user.displayName}}
            <span class="body-1 ml-3">
            Balance {{account.credit | currency}}
<!--              <a href="/"-->
<!--                 class="caption align-self-center mt-2 ml-1 text-decoration-none secondary&#45;&#45;text">-->
<!--                Purchase more credit?-->
<!--              </a>-->
            </span>
          </span>
          <span v-else @click="maybeBackToLogin">Vendor Console</span>
        </v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <div v-if="isLoggedIn" class="d-flex mr-2 search">
        <v-autocomplete
            dense
            filled
            solo-inverted
            flat
            full-width
            hide-no-data
            v-model="currentStore"
            :items="searchedStores"
            :search-input.sync="search"
            hide-details
            item-text="address"
            return-object
            label="Choose store"
            placeholder="Type to start searching">
          <template v-slot:selection="data">
            <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click="data.select"
                @click:close="remove(data.item)">
              {{storeTitle(data.item.vendor, data.item.suburb)}}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title v-text="storeTitle(data.item.vendor, data.item.suburb)"></v-list-item-title>
              <v-list-item-subtitle v-text="data.item.address"></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </div>
      <div v-if="user" class="d-flex">
        <v-btn outlined @click="signOut">Sign out</v-btn>
      </div>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import firebase from 'firebase/app'
import {mapState} from "vuex";
import CustomerService from "@/services/CustomerService";
export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      searchedStores: this.store == null ? [] : [this.store.address],
      search: this.store == null ? '' : this.store.address,
      searchTimerId: null,
      pollId: null
    }
  },
  created() {
    this.pollId = setInterval(this.getAccount, 5000)
  },
  destroyed() {
    clearInterval(this.pollId);
  },
  computed: {
    isLoggedIn: function () {
      return this.user !== null && this.account !== null
    },
    currentStore: {
      get() {
        return this.store
      },
      set(newStore) {
        if (newStore != null) {
          this.searchedStores = [newStore]
        } else {
          this.search = null;
        }
        this.updateStorePreference(newStore)
        this.searchStores(this.search)
      }
    },
    ...mapState({
      user: state => state.vendor.user,
      account: state => state.vendor.account,
      store: state => state.vendor.store
    }),
  },
  methods: {
    remove(item) {
      console.log('Removing item', item)
      this.search = null
      this.updateStorePreference(null)
    },
    getAccount() {
      const user = this.user;
      if (user == null) {
        return;
      }

      user.getIdToken()
        .then(token => CustomerService.getAccount(token))
    },
    signOut: function() {
      firebase.auth().signOut().then(() => {
        this.$store.commit('vendor/onSignOut')
        this.$router.push({name: 'Login'})
      })
      .catch((err) => console.error(err))
    },
    searchStores(searchText) {
      if (this.timerId != null) {
        clearTimeout(this.timerId)
      }

      this.timerId = setTimeout(() => {
        this.user.getIdToken()
            .then(token => CustomerService.searchStores(token, searchText == null ? '' : searchText))
            .then(response => response.stores || [])
            .then(stores => this.searchedStores = stores)
      }, 100)
    },
    updateStorePreference(store) {
      return this.user.getIdToken()
          .then(token => CustomerService.updateStorePreference(token, store != null ? store.websafeKey : null))
    },
    storeTitle(vendor, suburb) {
      return  vendor + ' ' + suburb + ' Store'
    },
    maybeBackToLogin() {
      if(this.$router.currentRoute.name !== 'Login') {
        this.$router.push({name: 'Login'})
      }
    }
  },
  watch: {
    search(newValue) {
      if (newValue != null && this.store != null && newValue === this.store.address) {
        this.searchedStores = [this.store]
        return
      }
      this.searchStores(newValue)
    }
  }
};
</script>

<style>
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}
</style>

<style scoped>
.search {
  width: 300px;
}
</style>
