export const cardsModule = {
    namespaced: true,
    state: {
        loadRequests: [],
        serverCount: null,
        nextPage: null,
    },
    mutations: {
        onNewPage(state, page) {
            if (page != null) {
                state.loadRequests.push(...(page.requests || []))
                state.serverCount = page.count
                state.nextPage = page.nextPage
            }
        },
        onInitialPage(state, page) {
            if (page != null) {
                state.loadRequests = (page.requests || [])
                state.serverCount = page.count;
                state.nextPage = page.nextPage;
            }
        },
        clearRequests(state) {
            state.loadRequests = []
            state.nextPage = null
            state.serverCount = null
        }
    },
    actions: {
        onNewPage({commit}, page) {
            commit('onNewPage', page)
        },
        onInitialPage({commit}, page) {
            commit('onInitialPage', page)
        },
        clearRequests({commit}) {
            commit('clearRequests')
        }
    },
    modules: {

    }
}
