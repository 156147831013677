import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import store from './store'
import "firebase/auth";
import firebase from "firebase/app";
import router from './router'
import {formatCurrency} from "@/store/shared";

// Initialise firebase.
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
};
firebase.initializeApp(firebaseConfig)

// Check login state on load
const unsubscribe = firebase.auth().onAuthStateChanged(function(user) {
  store.commit('vendor/onLogin', user)
  Vue.config.productionTip = false
  Vue.filter('currency', formatCurrency)
  new Vue({
    vuetify,
    store,
    router,
    render: h => h(App)
  }).$mount('#app')
  unsubscribe()
});
