import Vue from 'vue'
import Vuex from 'vuex'
import {vendorModule} from "@/store/vendor";
import {cardsModule} from "@/store/cards";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    vendor: vendorModule,
    cards: cardsModule
  }
})
