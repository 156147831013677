export function formatCurrency (value) {
    if (typeof value !== "number" && typeof  value !== "string") {
        return value;
    }

    if (typeof value === "string") {
        value = Number(value)
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
    return formatter.format(value);
}
