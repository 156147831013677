import * as axios from "axios";
import store from "../store"
import firebase from "firebase/app";

const client = axios.create({
    baseURL: process.env.VUE_APP_WLT_API,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

export default {
    getAccount(token) {
        return client.get('/get_customer_account', {headers: {'Authorization': `Basic ${token}`}})
            .then(response => response.data)
            .then(account => {
                store.commit('vendor/onAccount', account)
            })
    },
    getAccountProfile(token) {
        return client.get('user/get_account', {headers: {'Authorization': `Basic ${token}`}})
            .then(response => response.data)
            .then(accountAndPref => {
                store.commit('vendor/onAccountProfile', accountAndPref)
                return accountAndPref
            })
    },
    resetPassword(token, password) {
        return client.post('/user/reset_password',
            {password: password},
            {headers: {'Authorization': `Basic ${token}`}})
            .then(response => response.data)
            .then(account => {
                store.commit('vendor/onAccountProfile', account)
                return account.account
            })
            .then(account => firebase.auth().signInWithEmailAndPassword(account.email, password))
    },
    updateStorePreference(token, storeKey) {
        return client.post(
            '/user/update_store_preference',
            {storeKey: storeKey},
            {headers: {'Authorization': `Basic ${token}`}})
            .then(response => response.data)
            .then(accountAndPref => {
                store.commit('vendor/onAccountProfile', accountAndPref)
                return accountAndPref
            })
    },
    searchStores(token, searchText) {
        return client.get(
            `/stores/search_stores?searchText=${searchText}`,
            {headers: {'Authorization': `Basic ${token}`}})
            .then(response => response.data)
    },
    createLoadCardRequest(token, cardId, storeKey, amount, name, email, phone, notes, joinMailing,
                          paymentCardNumber, expiryMonth, expiryYear, cvc, nameOnCard) {
        const params = {
            cardId: cardId,
            storeId: storeKey,
            amount: amount,
            name: name,
            email: email,
            phone: phone,
            notes: notes,
            joinMailing: joinMailing,
            paymentCardNumber: paymentCardNumber,
            expiryMonth: expiryMonth,
            expiryYear: expiryYear,
            cvc: cvc,
            nameOnCard: nameOnCard
        }
        return client.post(
            '/cards/create_load_request',
            params,
            {headers: {'Authorization': `Basic ${token}`}})
            .then(response => response.data)
    },
    checkBalance(token, cardId) {
        return client.get(
            `/cards/check_balance?cardId=${cardId}`,
            {headers: {'Authorization': `Basic ${token}`}})
            .then(response => response.data)
    },
    searchStoreRequests(token, storeKey, pageSize, cursorKey) {
        const nextPageQuery = cursorKey != null ? `&cursorKey=${cursorKey}` : ''
        const pageSizeQuery = pageSize != null ? `&pageSize=${pageSize}` : ''
        return client.get(
            `/cards/get_store_loads?storeId=${storeKey}${nextPageQuery}${pageSizeQuery}`,
            {headers: {'Authorization': `Basic ${token}`}})
            .then(response => {
                console.log(response.data)
                return response.data
            })
    }
}

