export const vendorModule = {
    namespaced: true,
    state: {
        user: null,
        account: null,
        store: null
    },
    mutations: {
        onLogin(state, user) {
            if (user !== null) {
                state.user = user
            }
        },
        onAccount(state, account) {
            if (account !== null) {
                state.account = account
            }
        },
        onAccountProfile(state, profile) {
            if (profile !== null) {
                state.account = profile.account
                state.store = profile.store
            }
        },
        onSignOut(state) {
            state.user = null
            state.account = null
            state.store = null
        },
        onUpdateStore(state, store) {
            state.store = store
        }
    },
    actions: {
        onLoginSuccess({commit}, user) {
            commit('onLogin', user)
        },
        onAccountProfile({commit}, account) {
            commit('onAccountProfile', account)
        }
    },
    modules: {
    }
}
