<template>
  <v-card>
    <v-form ref='form' v-model="isFormValid">
      <v-card-text>
        <v-row class="px-2">
          <v-col>
            <div v-if="isNoStoreError" class="text-body-2 red--text">
              Choose store to load card.
            </div>
            <v-select
                ref="action"
                class="pa-0"
                outlined
                dense
                item-name="Action"
                item-value="Check Balance"
                disable-sort
                :items="actions"
                v-model="action"
                hide-details
                :error="isNoStoreError"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="px-2">
          <v-col cols="8">
            <v-text-field ref="cardId"
                          outlined
                          dense
                          single-line
                          type="text"
                          label="Card ID"
                          :rules="cardRules"
                          v-model="cardId"
                          hint="Alphanumeric characters at the bottom right of the card"></v-text-field>
            <div class="pa-2" v-if="expiry">
              Expiry: {{ expiry }}
            </div>
          </v-col>
          <v-col cols="4">
            <v-text-field ref="balance"
                          outlined
                          single-line
                          dense
                          min="0"
                          type="number"
                          :rules="balanceRules"
                          label="Balance"
                          hint="Balance contains 0-9 characters only."
                          v-model="balance"
                          prepend-inner-icon="mdi-currency-usd"
                          :disabled="action==='Check Balance'">

            </v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="px-2">
          <v-col class="pr-1">
            <v-text-field
                outlined
                single-line
                dense
                label="Customer name (optional)"
                hint="Customer name"
                v-model="name"
                :disabled="action !== 'Load Card'">

            </v-text-field>
          </v-col>
          <v-col class="pl-1">
            <v-text-field
                outlined
                single-line
                dense
                label="Customer phone (optional)"
                hint="Customer phone number"
                v-model="phone"
                :disabled="action !== 'Load Card'">

            </v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="px-2" justify="center" align="center">
          <v-col cols="8">
            <v-text-field
                outlined
                single-line
                hide-details
                dense
                label="Customer Email (optional)"
                hint="Customer email"
                v-model="email"
                :disabled="action !== 'Load Card'">

            </v-text-field>
          </v-col>
          <v-col class="text-center" offset="1">
            <v-checkbox
                dense
                class="text-center mt-0"
                hide-details
                label="Join WLT Mailing List (optional)"
                v-model="joinMailingList"
                :disabled="email == null || email === ''">
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row class="px-2" justify="center" align="center">
          <v-col>
            <v-textarea
                outlined
                auto-grow
                clearable
                label="Notes"
                hide-details
                v-model="notes"
                rows="2">

            </v-textarea>
          </v-col>
        </v-row>
        <v-row no-gutters class="px-2">
          <v-col v-if="submitSuccessMessage">
            <div class="text-caption teal--text">
              {{ submitSuccessMessage }}
            </div>
          </v-col>
          <v-col v-if="isBalanceWarning" >
            <div class="orange--text darken-4 text-caption">
              Credit is running low. For this request to be approved, you must have more credit than the requested
              amount.
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-switch
                      label="Pay by card"
                      class="px-2"
                      hide-details
                      v-model="payByCard"
                      :disabled="!isLoadCard"></v-switch>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-2 px-2">
          <v-col>
            <v-text-field
                ref="cardNumber"
                outlined
                single-line
                dense
                counter
                prepend-inner-icon="mdi-credit-card-outline"
                label="Card Number"
                hint="Card number"
                minlength="16"
                maxlength="16"
                v-model="cardNumber"
                :required="payByCard"
                :rules="paymentCardRules"
                type="number"
                :disabled="!isLoadCard || !payByCard">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="px-2">
          <v-col class="expiry">
            <v-text-field
                ref="expiryMonth"
                outlined
                single-line
                dense
                label="Expiry Month"
                hint="Expiry Month"
                minlegth="2"
                maxlength="2"
                v-model="expiryMonth"
                counter
                :required="payByCard"
                :rules="expiryMonthRules"
                type="number"
                :disabled="!isLoadCard || !payByCard">

            </v-text-field>
          </v-col>
          <v-col class="divider pa-2">
            /
          </v-col>
          <v-col class="expiry">
            <v-text-field
                ref="expiryYear"
                outlined
                single-line
                dense
                counter
                v-model="expiryYear"
                minlegth="2"
                maxlength="2"
                :required="payByCard"
                label="Expiry Year"
                hint="Expiry Year"
                type="number"
                :rules="expiryYearRules"
                :disabled="!isLoadCard || !payByCard">

            </v-text-field>
          </v-col>
          <v-col class="cvc ml-3">
            <v-text-field
                ref="cvc"
                outlined
                single-line
                dense
                counter
                minlength="3"
                maxlength="4"
                v-model="cvc"
                label="CVC"
                hint="CVC"
                :required="payByCard"
                type="number"
                :rules="cvcRules"
                :disabled="!isLoadCard || !payByCard">

            </v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="px-2">
          <v-col>
            <v-text-field
                ref="nameOnCard"
                outlined
                single-line
                dense
                v-model="nameOnCard"
                label="Name on card"
                hint="Name on card"
                :required="payByCard"
                :rules="nameOnCardRules"
                :disabled="!isLoadCard || !payByCard">

            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed text x-large color="primary"
               class="mr-2"
               :loading="isLoading"
               @click="() => action === 'Load Card' ? createCardLoadRequest() : checkBalance()"
               :disabled="!isFormValid || maybeCardDisabled">
          {{ shortAction }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import {mapState} from "vuex";
import CustomerService from "@/services/CustomerService";

const ACTION_CHECK_BALANCE = 'Check Balance'
const ACTION_LOAD_CARD = 'Load Card'
const LOAD_CARD_SUCCESS = 'Load Success'
export default {
  name: "CardLoader",
  data: () => ({
    cardId: null,
    balance: null,
    expiry: null,
    isLoading: false,
    actions: [ACTION_LOAD_CARD, ACTION_CHECK_BALANCE],
    action: ACTION_LOAD_CARD,
    cardErrorMessage: null,
    submitSuccessMessage: null,
    isFormValid: false,
    loadSuccessTimeout: null,

    name: null,
    phone: null,
    notes: null,
    email: null,
    joinMailingList: false,

    payByCard: false,
    cardNumber: null,
    expiryMonth: null,
    expiryYear: null,
    cvc: null,
    nameOnCard : null
  }),
  computed: {
    ...mapState({
      account: state => state.vendor.account,
      user: state => state.vendor.user,
      store: state => state.vendor.store
    }),
    credit: function() {
      return this.account?.credit || 0.0
    },
    isBalanceWarning() {
      return this.action === ACTION_LOAD_CARD && !this.payByCard && this.balance != null && this.balance > this.credit
    },
    shortAction: function () {
      return this.action.split(' ')[0]
    },
    isNoStoreError() {
      if (this.action === ACTION_LOAD_CARD) {
        return this.store == null;
      }
      return false
    },
    cardErrorRule() {
      return this.cardErrorMessage == null || this.cardErrorMessage;
    },
    cardRules: function () {
      return [
        value => !!value || 'Card Id is required',
        this.cardErrorRule
      ]
    },
    balanceRules: function () {
      return [
        value => (this.action === ACTION_LOAD_CARD ? !!value : true) || 'Balance is required',
        value => (this.action === ACTION_LOAD_CARD ? value > 0 : true) || 'Load card balance must be greater than 0.0'
      ]
    },
    isLoadCard: function() {
      return this.action === ACTION_LOAD_CARD
    },
    paymentCardRules: function() {
      return [
        value => this.payByCard ? value != null && !!value || 'Card number is required.' : true,
        value => this.payByCard ? value.length === 16 || 'Card must be 16 digits' : true,
        value => this.payByCard ? this.isLuhnVerified(value) : true
      ]
    },
    expiryMonthRules: function() {
      return [
          value => this.payByCard ? value != null && !!value || 'Card expiry month is required.' : true,
          value => this.payByCard ? this.isValidExpiryMonth(value) : true
      ]
    },
    expiryYearRules: function() {
      return [
        value => this.payByCard ? value != null && !!value || 'Card expiry year is required.' : true,
        value => this.payByCard ? this.isValidExpiryYear(value) : true
      ]
    },
    cvcRules: function() {
     return [
         value => this.payByCard ? value != null && !!value || 'CVC is required' : true,
         value => this.payByCard ? this.isValidCVC(value) : true
     ]
    },
    nameOnCardRules: function() {
      return [
          value => this.payByCard ? value != null && !!value || 'Name on card is required.' : true
      ]
    },
    maybeCardDisabled() {
      if (!this.payByCard) {
        return false
      }

      return this.cardNumber == null ||
          this.expiryMonth == null ||
          this.expiryYear == null ||
          this.cvc == null ||
          this.nameOnCard == null
    }
  },
  methods: {
    createCardLoadRequest: function () {
      if (this.user == null) {
        return
      }

      const cardId = (this.cardId || '').trim()
      if (cardId === '') {
        return;
      }

      if (this.balance == null || this.balance <= 0.0) {
        return;
      }

      if (this.store == null) {
        return;
      }

      this.isLoading = true;
      this.user.getIdToken()
          .then(token => CustomerService.createLoadCardRequest(
              token,
              cardId,
              this.store.websafeKey,
              this.balance,
              this.name,
              this.email,
              this.phone,
              this.notes,
              this.joinMailingList,
              this.payByCard ? this.cardNumber : null,
              this.payByCard ? this.expiryMonth : null,
              this.payByCard ? this.expiryYear : null,
              this.payByCard ? this.cvc : null,
              this.payByCard ? this.nameOnCard : null
          ))
          .then((request) => {
            this.submitSuccessMessage = LOAD_CARD_SUCCESS
            this.$root.$emit('request-success', request)
            this.$refs.cardId.reset()
            this.$refs.balance.reset()
            this.clearCard()
            const t = this;
            this.loadSuccessTimeout = setTimeout(function () {
              t.submitSuccessMessage = null
            }, 30000)
          })
          .catch(error => {
            const response = error.response
            const errorStatus = response.status;
            const errorData = response.data
            console.log(errorData)
            if (errorStatus === 400) {
              this.cardErrorMessage = errorData.error.message
            } else {
              this.cardErrorMessage = 'Uh - oh something went wrong.'
            }
            console.log(error)
          })
          .finally(() => this.isLoading = false)
    },
    checkBalance: function() {
      if (this.user == null) {
        return;
      }

      const cardId = (this.cardId || '').trim()
      if (cardId === '') {
        return;
      }

      this.isLoading = true;
      this.user.getIdToken()
          .then(token => CustomerService.checkBalance(token, cardId))
          .then(data => {
            this.balance = data.balance;
            const maybeExpiry = data.expiry;
            this.expiry = maybeExpiry != null ? maybeExpiry.replace("T00:00:00", "").replace("T23:59:59", "") : null;
          })
          .catch(error => {
            const response = error.response
            const errorStatus = response.status;
            const errorData = response.data
            console.log(errorData)
            if (errorStatus === 400) {
              this.cardErrorMessage = errorData.error.message
            } else {
              this.cardErrorMessage = 'Uh - oh something went wrong.'
            }
            console.log(error)
          })
          .finally(() => this.isLoading = false)
    },
    isLuhnVerified(value) {
      if (value == null) {
        return 'Card number cannot be empty'
      }

      const digits = value.split('')
          .map(v => Number(v))
          .reverse()

      let sumOdd = 0
      let sumEven = 0
      for (let i = 0; i < digits.length; i++) {
        const digitNum = i + 1
        const isEven = (digitNum % 2 === 0)
        const digit = digits[i]
        if (isEven) {
          const twiceDigit = 2 * digit
          sumEven += Math.floor(twiceDigit / 10) + (twiceDigit % 10)
        } else {
          sumOdd += digit
        }
      }
      const luhn_sum = sumOdd + sumEven
      if (luhn_sum % 10 !== 0) {
        return 'Card number is not valid.'
      }
      return true
    },
    isValidExpiryMonth(value) {
      if (value == null) {
        return 'Month cannot be empty.'
      }

      if (value.length !== 2) {
        return 'Month must be MM format. E.g 01'
      }

      const month = Number(value)
      if (month < 1 || month > 12) {
        return 'Month must be between 01 - 12'
      }

      return true
    },
    isValidExpiryYear(value) {
      if (value == null) {
        return 'Year cannot be empty.'
      }

      if (value.length !== 2) {
        return 'Year must be formatted \'YY\'.'
      }

      return true
    },
    isValidCVC(value) {
      if (value == null) {
        return 'CVC is required.'
      }

      if (value.length < 3 || value.length > 4) {
        return 'CVC must be 3-4 digits.'
      }

      return true
    },
    clearCard() {
      this.$refs.cardNumber.reset()
      this.$refs.expiryMonth.reset()
      this.$refs.expiryYear.reset()
      this.$refs.cvc.reset()
      this.$refs.nameOnCard.reset()
    }
  },
  watch: {
    cardId: function (newValue) {
      this.cardErrorMessage = null
      if (newValue != null && this.submitSuccessMessage != null) {
        clearTimeout(this.loadSuccessTimeout)
        this.submitSuccessMessage = null;
      }
      this.expiry = null;
    },
    balance: function (newValue) {
      if (newValue != null && this.submitSuccessMessage != null) {
        clearTimeout(this.loadSuccessTimeout)
        this.submitSuccessMessage = null;
      }
    },
    action: function (newVal) {
      if (newVal === ACTION_CHECK_BALANCE) {
        this.$refs.balance.validate()
        this.balance = null;
      }
      this.submitSuccessMessage = null
    },
    email(newValue) {
      if (newValue == null || newValue === '') {
        this.joinMailingList = false
      }
    },
    payByCard() {
        this.$refs.cardNumber.validate()
        this.$refs.expiryMonth.validate()
        this.$refs.expiryYear.validate()
        this.$refs.cvc.validate()
        this.$refs.nameOnCard.validate()
    }
  }
}
</script>

<style scoped>

.col.expiry {
  min-width: 100px;
  max-width: 150px;
}
.col.divider {
  min-width: 20px;
  max-width: 20px;
}
.col.cvc {
  min-width: 50px;
}
</style>
