import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from "@/components/dashboard/Dashboard";
import Login from "@/components/login/Login";
import firebase from "firebase/app";
import ResetPassword from "@/components/reset/ResetPassword";
Vue.use(VueRouter)

import store from "../store"
import AdminService from "@/services/CustomerService";
import ForgotPassword from "@/components/ForgotPassword/ForgotPassword";

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/resetPassword',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/forgotPassword',
    name: 'ForgotPassword',
    component: ForgotPassword
  }
]


function getAccount(currentUser) {
  return store.state.vendor.account != null
      ? Promise.resolve(store.state.vendor.account)
      : currentUser.getIdToken().then(token => AdminService.getAccountProfile(token))
}

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name === 'ForgotPassword') {
    next();
    return;
  }

  const currentUser = firebase.auth().currentUser
  if (to.name !== 'Login' && currentUser == null) {
    next({ name: 'Login' });
    return;
  }

  if (to.name === 'Login') {
    next();
    return;
  }

  getAccount(currentUser)
      .then((account) => {
        const requiresReset = account?.requiresReset || false;
        if (to.name !== 'ResetPassword' && requiresReset) {
          next({name: 'ResetPassword'})
        } else {
          next();
        }
      })
      .catch((error) => {
        console.log(error);
        next();
      })
})

export default router
