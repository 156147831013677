<template>
  <v-container class="fill-height">
    <v-row class="justify-center fill-height">
      <v-col cols="10" sm="8" md="8" lg="6">
        <v-card class="mt-3 fill-height" elevation="5" :loading="isSubmitting">
          <v-row class="justify-center">
            <v-col sm="8">
              <v-img :src="require('../../assets/wlt-logo.jpg')"
                     class="mx-auto"
                     contain
                     max-height="150"
              ></v-img>
              <div class="mb-3 text-body-1 font-weight-bold text-center">
                Retrieve your password here
              </div>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-form ref="forgot" v-model="isValidForm" v-on:submit.prevent="resetPassword" class="mt-3">
            <v-row dense class="justify-center">
              <v-col cols="10" sm="8">
                <div class="text-body-2 text--secondary text-center">
                  Enter your email address in the box below and click the reset password button
                </div>
              </v-col>
            </v-row>
            <v-row dense class="justify-center">
              <v-col cols="10" sm="8">
                <v-text-field
                    class="mt-3"
                    v-model="email"
                    :rules="[rules.emailValid, rules.emailRequired]"
                    :disabled="isSubmitting"
                    label="E-mail"
                    outlined
                    @keyup.enter="resetPassword"
                    required>
                </v-text-field>
                <div v-if="resetEmailSuccess" class="text-caption teal--text">
                  {{this.resetEmailSuccess}}
                </div>
              </v-col>
            </v-row>
            <v-row dense justify="center">
              <v-col cols="10" sm="8" class="text-right">
                <v-btn right large outlined color="primary"
                       @click="resetPassword"
                       :disabled="!isValidForm"
                       :loading="isSubmitting">Reset Password</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from 'firebase/app'

export default {
  name: "ForgotPassword",
  data: () => ({
    email: '',
    rules: {
      emailRequired: v => !!v || 'E-mail is required',
      emailValid: v => /.+@.+/.test(v) || 'E-mail must be valid',
    },
    resetEmailSuccess: null,
    isSubmitting: false,
    isValidForm: false
  }),
  methods: {
    resetPassword() {
      if (this.email == null || this.email === '') {
        return
      }

      if (this.rules.emailValid(this.email) === 'E-mail must be valid') {
        return
      }

      this.isSubmitting = true;
      const auth = firebase.auth()
      auth.sendPasswordResetEmail(this.email)
          .then(() => {
            this.resetEmailSuccess = `An email has been sent to ${this.email} if it is a valid account`
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
            // ..
          }).finally(() => this.isSubmitting = false)
    }
  },
  watch: {
    email() {
      this.resetEmailSuccess = null
    }
  }
}
</script>

<style scoped>

</style>
